const initialState = {
  user: (() => {
    try {
      return JSON.parse(window.localStorage.user);
    } catch (e) {
      return null;
    }
  })(),
};

const UserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
