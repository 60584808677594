import money from "../../../assets/icons/money.svg";
import fire from "../../../assets/icons/fire.svg";
import medal from "../../../assets/icons/honor_medal.svg";
import trophy from "../../../assets/icons/trophy.svg";
import home from "../../../assets/icons/home.svg";
import users from "../../../assets/icons/users.svg";
import plus from "../../../assets/icons/plus.filled.circle.svg";
import bars from "../../../assets/icons/bars.svg";
import live from "../../../assets/icons/sensors.svg";
import merc from "../../../assets/img/logomerch.png";

import "./styles.scss";

//awards icons
export const MoneyIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={money}
  ></img>
);
export const FireIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={fire}
  ></img>
);
export const MedalIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "30px" }}
    className={props.active ? "active" : ""}
    src={medal}
  ></img>
);
export const TrophyIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={trophy}
  ></img>
);

//navbaricons
export const HomeIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={home}
  ></img>
);
export const UsersIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={users}
  ></img>
);
export const PlusIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={plus}
  ></img>
);

export const BarsIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={bars}
  ></img>
);

export const LogoMerc = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className={props.active ? "active" : ""}
    src={merc}
  ></img>
);

export const LiveIcon = (props: { size?: number; active?: boolean }) => (
  <img
    style={{ width: props.size ? props.size : "35px" }}
    className="live"
    src={live}
  ></img>
);
