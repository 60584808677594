import { Input, Button, Col, message } from "antd";
import { Form } from "antd";
import { useState } from "react";
import { Auth } from "../../../controllers/auth";
import { REGEX_EMAIL } from "../../../controllers/utils";
import "./styles.scss";

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const doAuth = async (data: any) => {
    try {
      setLoading(true);
      console.log(data);
      await Auth({
        email: data.username,
        password: data.password,
      });
    } catch (e) {
      message.error("Usuario o contraseña no válidos");
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name="Iniciar sesión"
      style={{ maxWidth: 600 }}
      onFinish={doAuth}
      autoComplete="off"
    >
      <Col>
        <label>Usuario</label>
      </Col>

      <Form.Item
        wrapperCol={{ span: 24 }}
        name="username"
        rules={[
          {
            required: true,
            message: "Ingrese un usuario válido!",
            pattern: REGEX_EMAIL,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Col>
        <label>Contraseña</label>
      </Col>

      <Form.Item
        wrapperCol={{ span: 24 }}
        name="password"
        rules={[{ required: true, message: "Ingrese su contraseña!" }]}
      >
        <Input.Password />
      </Form.Item>
      <br />
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button
          loading={loading}
          size={"large"}
          block
          htmlType="submit"
          className="fuschia"
        >
          Iniciar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
