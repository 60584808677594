import {Input, Button, Col, message} from "antd";
import {Form} from "antd";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Auth, authCode, getCode} from "../../../controllers/auth";
import {REGEX_EMAIL} from "../../../controllers/utils";
import "./styles.scss";

const LoginFormCode: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setcode] = useState<boolean>(false);
  const [t] = useTranslation();

  const doAuth = async (data: any) => {
    try {
      if (data.username && !data.code) {
        setLoading(true);
        console.log(data);
        await getCode({
          username: data.username,
        });
        setcode(true);
      }

      if (data.username && data.code) {
        setLoading(true);
        console.log(data);
        await authCode({
          username: data.username,
          code: data.code,
        });
      }

      setLoading(false);
    } catch (e) {
      message.error("Usuario no encontrado");
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name="Iniciar sesión"
      style={{maxWidth: 600}}
      onFinish={doAuth}
      autoComplete="off"
    >
      <Col>
        <label>{`*${t("EMAIL")}`}</label>
      </Col>

      <Form.Item
        wrapperCol={{span: 24}}
        name="username"
        rules={[
          {
            required: true,
            message: "Ingrese un usuario válido!",
            pattern: REGEX_EMAIL,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <br />
      {code ? (
        <>
          {" "}
          <Col>
            <label>{`*${t("Codigo")}`}</label>
          </Col>
          <Form.Item
            wrapperCol={{span: 24}}
            name="code"
            rules={[
              {
                required: true,
                message: "Ingrese un código válido",
              },
            ]}
          >
            <Input />
          </Form.Item>{" "}
          <br />
        </>
      ) : (
        <></>
      )}

      <Form.Item wrapperCol={{span: 24}}>
        <Button
          loading={loading}
          type="primary"
          size={"large"}
          block
          htmlType="submit"
        >
          Iniciar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginFormCode;
