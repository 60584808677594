import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./langs/en";
import es from "./langs/es";

i18n.use(initReactI18next).init({
  resources: {
    en: {translation: en},
    es: {translation: es},
  },
  lng: window.navigator.language.substr(0, 2),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
