import React from "react";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  BrowserRouter,
} from "react-router-dom";
import "./App.scss";
import MainLayout from "./interfaces/layouts/mainLayout";
import LoginView from "./interfaces/views/login/loginView";

import FullLayout from "./interfaces/layouts/fullLayout";
import HomeView from "./interfaces/views/homeView/home";
import HomeLayout from "./interfaces/layouts/homeLayout";

const App: React.FC = () => {
  const user = useSelector((state: any) => {
    return state.user.user;
  });

  interface IProtectedRouteProps {
    user: string;
    redirectPath?: string;
  }
  const ProtectedRouteMiddleware = (props: IProtectedRouteProps) => {
    const { user, redirectPath } = props;

    if (!user) {
      return <Navigate to={redirectPath ?? "/login"} replace />;
    }

    return <Outlet />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Navigate to={"/login"}></Navigate>} />
        <Route
          path="login"
          element={(() => {
            if (user) return <Navigate to={"/home"} />;
            else return <LoginView />;
          })()}
        />
        <Route
          path="home"
          element={<HomeLayout content={<HomeView user={user} />} />}
        />

        <Route path="*" element={<h1>Not found</h1>}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
