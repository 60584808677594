const EsLang = {
  TRANSACTIONS: "Transacciones",
  LOGOUT: "Cerrar sesión",
  EMAIL: "Correo",
  PHONE: "Teléfono",
  LOGIN: "Iniciar Sesión",
  EXPLORE: "Explorar"
};

export default EsLang;
