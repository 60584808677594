import logo from "../../../assets/img/logo.png";

const Logo: React.FC<any> = (props: {size: number}) => (
  <img
    alt=""
    style={{width: props.size ? props.size : "200px"}}
    className="logo"
    src={logo}
  />
);

export default Logo;
