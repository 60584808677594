import { Row, Col, Divider } from "antd";
import { MoneyIcon, FireIcon, MedalIcon, TrophyIcon } from "../icons/icons";

export interface IAwardsBarProps {
  money?: string;
  performance?: string;
  points?: string;
  rank?: string;
}

const AwardsBar: React.FC<IAwardsBarProps> = (props: IAwardsBarProps) => {
  return (
    <>
      <Row justify={"center"} align={"middle"} gutter={5}>
        <Col xs={5}>
          <span className="tc block">
            <MoneyIcon />
          </span>
          <span className="t12 block tc tgray">DINERO</span>
          <span className="t14 bold block tc">{props.money ?? "$0"}</span>
        </Col>
        <Divider type={"vertical"}></Divider>
        <Col xs={5}>
          <span className="tc block">
            <FireIcon active={true} />
          </span>
          <span className="t12 block tc tgray">RACHA</span>
          <span className="t14 bold block tc">{props.performance ?? "-"}</span>
        </Col>
        <Divider type={"vertical"}></Divider>
        <Col xs={5}>
          <span className="tc block">
            <MedalIcon />
          </span>
          <span className="t12 block tc tgray">PUNTOS</span>
          <span className="t14 bold block tc">{props.points ?? "0"}</span>
        </Col>
        <Divider type={"vertical"}></Divider>
        <Col xs={5}>
          <span className="tc block">
            <TrophyIcon />
          </span>
          <span className="t12 block tc tgray">RANK MES</span>
          <span className="t14 bold block tc">{props.rank ?? "-"}</span>
        </Col>
      </Row>
    </>
  );
};

export default AwardsBar;
