import {message} from "antd";
import axios from "axios";
import {ActionsetUser} from "../store/actions";
import store from "../store";

const dispatch = store.dispatch;

export const Logout = () => {
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("jwt");
  const dispatch = store.dispatch;

  dispatch(ActionsetUser(null));
};

export interface ICredentialsProps {
  email: string;
  password: string;
}

export interface ICredentialsCodeProps {
  username: string;
}

export interface ICredentialsCodeAuthProps {
  username: string;
  code: string;
}

export const getToken = () => window.localStorage.jwt;

export const getMe = async () => {
  const user = (
    await axios.get(`${process.env.REACT_APP_API_HOST}/users/me`, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    })
  ).data;
  return user.data;
};

export const Auth = async (credentials: ICredentialsProps) => {
  const data = (
    await axios.post(
      `${process.env.REACT_APP_API_HOST}/auth/login`,
      credentials
    )
  ).data.data;

  const jwt = data.access_token;
  window.localStorage.jwt = jwt;
  const me = await getMe();
  const user = {me, jwt};
  window.localStorage.user = JSON.stringify(user);
  message.success("Sesión iniciada");

  setTimeout(() => {
    dispatch(ActionsetUser(user));
  }, 2000);
};

export const getCode = async (credentials: ICredentialsCodeProps) => {
  await axios.post(
    `${process.env.REACT_APP_API_HOST}/authv2/code`,
    credentials
  );
};

export const authCode = async (credentials: ICredentialsCodeAuthProps) => {
  const data = (
    await axios.post(
      `${process.env.REACT_APP_API_HOST}/authv2/login/code`,
      credentials
    )
  ).data;

  const jwt = data.jwt;
  window.localStorage.jwt = jwt;
  const me = await getMe();
  const user = {me, jwt};
  window.localStorage.user = JSON.stringify(user);
  message.success("Sesión iniciada");

  setTimeout(() => {
    dispatch(ActionsetUser(user));
  }, 2000);
};
