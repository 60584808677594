import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import "./styles.scss";
import Avatars from "../../components/avatars/avatars";
import {
  BellOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  FireIcon,
  LiveIcon,
  LogoMerc,
  MedalIcon,
  MoneyIcon,
  TrophyIcon,
} from "../../components/icons/icons";
import AwardsBar, {
  IAwardsBarProps,
} from "../../components/awardsBar/awardsBar";
import { Content } from "antd/es/layout/layout";
import EventCard from "../../components/eventCard/eventCard";

export interface HomeProps {
  user: any;
}
const HomeView: React.FC<HomeProps> = (props: HomeProps) => {
  const statsAwards: IAwardsBarProps = {
    money: "$530.000",
    performance: "7/10",
    points: "58",
    rank: "#12",
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Eventos`,
      children: (
        <>
          <div className="carousel">
            <div className="carousel-container">
              <div className="carousel-item ">
                <EventCard title="$1.300.000" paid live name="AMG F1" />
              </div>
              <div className="carousel-item ">
                <EventCard
                  title="iPhone 14 pro"
                  name="Nombre del Evento de prueba festival II"
                />
              </div>
              <div className="carousel-item ">
                <EventCard title="$4.850.000" name="Adidas" />
              </div>
              <div className="carousel-item ">
                <EventCard title="$1.300.000" live name="AMG F1" />
              </div>
              <div className="carousel-item ">
                <EventCard title="$4.850.000" name="Adidas" />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: `Participando`,
      children: (
        <>
          <div className="carousel">
            <div className="carousel-container">
              <div className="carousel-item ">
                <EventCard title="$1.300.000" name="AMG F1" />
              </div>
              <div className="carousel-item ">
                <EventCard title="iPhone 14 pro" name="Apple" />
              </div>
              <div className="carousel-item ">
                <EventCard title="$4.850.000" name="Adidas" />
              </div>
              <div className="carousel-item ">
                <EventCard title="$1.300.000" paid live name="AMG F1" />
              </div>
              <div className="carousel-item ">
                <EventCard title="$4.850.000" name="Adidas" />
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Row gutter={10}>
        <Col xs={18}>
          <Input
            className="search"
            prefix={<SearchOutlined className="icon" />}
          ></Input>
        </Col>
        <Col xs={6}>
          <Row justify={"center"} align={"middle"}>
            <Col xs={12}>
              <Button type={"ghost"} className="icon-header">
                <QuestionCircleOutlined />
              </Button>
            </Col>
            <Col xs={12}>
              <Button type={"ghost"} className="icon-header">
                <BellOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />

      <AwardsBar {...statsAwards} />

      <br />
      <>
        <Row align={"middle"}>
          <Col xs={19}>
            <h2>Retos diarios</h2>
          </Col>
          <Col xs={5}>
            <Button className="tgray" type={"link"}>
              Ver Todos
            </Button>
          </Col>
          <Col xs={24}>
            <Button
              type="ghost"
              className="curve"
              style={{
                display: "block",
                height: "fit-content",
                clear: "both",
                width: "100%",
                padding: "0",
                margin: "0",
              }}
            >
              <Card className="green-card curve">
                <Row gutter={10}>
                  <Col xs={16}>
                    <div style={{ textAlign: "left" }}>
                      <span
                        className="starts-in t14 curve block"
                        style={{ width: "fit-content" }}
                      >
                        Inicia en <b>1hr 5 min</b>
                      </span>
                      <Divider
                        style={{ margin: "10px 0", paddingRight: "20px" }}
                      ></Divider>
                      <span className="t14 block">Premio</span>
                      <span className="t25 bold block">$1.400.000</span>
                      <Avatars />
                    </div>
                  </Col>
                  <Col xs={8}>
                    <div style={{ lineHeight: "1.2" }}>
                      <span className="t25 block tl tc">MAY</span>
                      <span className="t70 bold block tc">18</span>
                      <Button className="fuschia center">Participar</Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Tabs
              defaultActiveKey="1"
              items={items}
              onChange={onChange}
              tabBarExtraContent={
                <Button className="tgray" type={"link"}>
                  Ver Todos
                </Button>
              }
            />
          </Col>
        </Row>
      </>
    </>
  );
};

export default HomeView;
