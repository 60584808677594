import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserFullNameUtil } from "../../controllers/utils";
import Logo from "../components/Logo/Logo";
import "./styles.scss";
import NavBarIcons from "../components/navbar/navbar";

export interface IHomeLayoutProps {
  content: ReactNode;
  back?: string;
}

const HomeLayout: React.FC<IHomeLayoutProps> = (props: IHomeLayoutProps) => {
  const { content, back } = props;
  const user: any = useSelector((state: any) => state.user?.user?.me);
  const navigate = useNavigate();
  return (
    <Layout style={{ minHeight: "100vh", background: "none" }}>
      <Layout.Content
        style={{ padding: "20px", paddingTop: "20px", paddingBottom: "120px" }}
      >
        {/* HEADER */}
        <Row>
          <Col xs={16}>
            <h1>{user ? `${getUserFullNameUtil(user)}` : "¡Hola!"}</h1>
          </Col>
          <Col xs={8}>
            <span
              style={{ float: "right", position: "relative", right: "-5px" }}
            >
              <Logo size="87px"></Logo>
            </span>
          </Col>
        </Row>
        {/* HEADER */}

        {back ? (
          <h3 style={{ cursor: "default" }} onClick={() => navigate(back)}>
            <ArrowLeftOutlined /> Volver
          </h3>
        ) : (
          <></>
        )}
        {content}
      </Layout.Content>

      <Layout.Footer className="navbar">
        <NavBarIcons />
      </Layout.Footer>
    </Layout>
  );
};

export default HomeLayout;
