import { Row, Col, Button, Avatar } from "antd";
import { BarsIcon, HomeIcon, PlusIcon, UsersIcon } from "../icons/icons";
import "./styles.scss";
import { userModel } from "../../../models/userModel";
import { useNavigate } from "react-router-dom";

export interface INavBarProps {
  user?: userModel;
}

const NavBarIcons: React.FC = (props: INavBarProps) => {
  const { user } = props;
  const navigate = useNavigate();

  return (
    <>
      <Row
        justify={"center"}
        gutter={20}
        align={"middle"}
        style={{ lineHeight: "3" }}
      >
        <Col xs={4}>
          <Button type={"ghost"} className="button-nav">
            <HomeIcon size={23} />
          </Button>
        </Col>
        <Col xs={4}>
          <Button type={"ghost"} className="button-nav">
            <UsersIcon size={26} />
          </Button>
        </Col>
        <Col xs={5}>
          <Button type={"ghost"} className="button-nav">
            <PlusIcon size={50} />
          </Button>
        </Col>
        <Col xs={4}>
          <Button type={"ghost"} className="button-nav">
            <BarsIcon size={25} />
          </Button>
        </Col>
        <Col xs={4}>
          <Button type={"ghost"} className="button-nav">
            {user ? (
              <Avatar
                size={30}
                src={
                  user.avatar ??
                  "https://xsgames.co/randomusers/avatar.php?g=pixel&key=2"
                }
                style={{ position: "relative", marginTop: "-10px" }}
              ></Avatar>
            ) : (
              <Avatar
                size={30}
                src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2"
                style={{ position: "relative", marginTop: "-10px" }}
                onClick={() => navigate("/")}
              ></Avatar>
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default NavBarIcons;
