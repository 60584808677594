import {userModel} from "../../models/userModel";
import JSZip from "jszip";
import {message} from "antd";
import moment from "moment";

export const getUserFullNameUtil = (user: userModel) =>
  `${user.first_name} ${user.last_name}`;

//REGEXS
export const REGEX_ALPHANUMERIC = /^[A-zÀ-ú0-9 ]{3,}$/g;
export const REGEX_TEXT = /^.{3,}$/g;
export const REGEX_NUMBER = /^[0-9 ]{1,}$/g;
export const REGEX_EMAIL =
  /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/g;
export const REGEX_PHONE = /^(\+[0-9]{1,3})?([0-9]{10,10})$/g;
export const REGEX_IP = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
export const REGEX_URL =
  /^localhost|(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?)((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}?$/;
export const REGEX_PIN = /^[0-9]{4}$/g;

export const beutifyXml = (xmlString: string) => {
  const prettier = require("prettier/standalone");
  const parserXML = require("@prettier/plugin-xml");
  return prettier.format(xmlString, {
    parser: "xml",
    plugins: [parserXML],
  });
};

export const downloadXMLFromString = (xmlString: string, fileName: string) => {
  const prettier = require("prettier/standalone");
  const parserXML = require("@prettier/plugin-xml");
  const element = document.createElement("a");
  const file = new Blob([beutifyXml(xmlString)], {type: "text/xml"});
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const textToBlob = (name: string, content: string, type: string) => {
  return {name, file: new Blob([content], {type: type})};
};

export const downloadZip = (files: any[]) => {
  try {
    const zip = new JSZip();

    files.map(file => {
      zip.file(file.name, file.file);
    });

    zip.generateAsync({type: "blob"}).then(content => {
      // Crea una URL del objeto Blob
      const url = URL.createObjectURL(content);

      // Crea un enlace <a> para descargar el archivo ZIP
      const link = document.createElement("a");
      link.href = url;
      link.download = `transactions_${moment().format("DD_MM_YYY")}.zip`;
      link.click();

      // Libera la URL creada
      URL.revokeObjectURL(url);
    });
  } catch (e) {
    message.error("Ups! ocurruio un error generando el zip");
  }
};

export const downloadJSONFromString = (content: string, fileName: string) => {
  const element = document.createElement("a");
  const file = new Blob([content], {type: "application/json"});
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const formatDateUtil = (value: string) =>
  moment
    .utc(value)
    .local()
    .format(process.env.REACT_APP_DATE_FORMAT || "DD-MM-YYYY hh:mm A");
