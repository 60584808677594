import { Button, Col, Layout, Row } from "antd";
import React from "react";
import LoginForm from "../../components/Forms/LoginForm";
import "./styles.scss";
import Logo from "../../components/Logo/Logo";
import LoginFormCode from "../../components/Forms/LoginFormCode";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface ILoginViewProps {}

const LoginView: React.FC = (props: ILoginViewProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Layout style={{ height: "100vh", background: "none" }}>
        <Row className="login-view" justify={"center"} align={"middle"}>
          <Col xs={24}>
            <Col offset={0} xs={24}>
              <Logo size={200} />
            </Col>
            <Col offset={0} xs={24}>
              <h2>{t("LOGIN")}</h2>
              <br></br>
            </Col>
            {process.env.REACT_APP_LOGIN_MODE &&
            process.env.REACT_APP_LOGIN_MODE.match("code") ? (
              <LoginFormCode />
            ) : (
              <LoginForm />
            )}
            <Row justify={"center"}>
              <Col>
                <Button onClick={() => navigate("/home")} type={"link"}>
                  {t("EXPLORE")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default LoginView;
