import { Card, Tag, Button, Space } from "antd";
import { LogoMerc, LiveIcon } from "../icons/icons";

import "./styles.scss";

const EventCard: React.FC<any> = (props: {
  paid: boolean;
  title: string;
  name: string;
  live: boolean;
}) => {
  return (
    <Button
      type="ghost"
      className="curve"
      style={{
        display: "block",
        height: "fit-content",
        clear: "both",
        width: "100%",
        padding: "0",
        margin: "0",
      }}
    >
      <div
        className={props.paid ? "event-home curve paid" : "event-home curve"}
      >
        {props.paid ? (
          <span className="t14 tc block paid" style={{ position: "absolute" }}>
            De Pago
          </span>
        ) : (
          <></>
        )}
        <span className="t18 tc block bold">{props.title ?? ""}</span>

        <span
          className="t12 block"
          style={{ width: "100px !important", marginTop: "10px" }}
        >
          {props.name ?? ""}
        </span>
        <span className="block" style={{ marginTop: "10px" }}>
          <LogoMerc size={80} />
        </span>
        {props.live ? (
          <Tag color={"black"} className="live-tag">
            <LiveIcon size={22} />
            EN VIVO
          </Tag>
        ) : (
          <Tag style={{ marginTop: "10px" }}>
            {" "}
            Feb 22 <b>09:00</b>
          </Tag>
        )}
        <span className="t10 bold block" style={{ marginTop: "5px" }}>
          {props.live ? (
            <span>48,132 personas jugando</span>
          ) : (
            <span>12,683 jugadores participando</span>
          )}
        </span>

        <Button className="fuschia center curve" style={{ marginTop: "10px" }}>
          Participar
        </Button>
      </div>
    </Button>
  );
};

export default EventCard;
